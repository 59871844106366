<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Add User Type') }}</h3>
                </div>
                <form @submit.prevent="addUserType" class="invite-form">
                    <div class="form-group">
                        <input v-model="userType.name" type="text" :class="['form-control', {'has-error': errors['name']}]" :placeholder="$t('Name')">

                        <FormErrors v-if="errors[`name`]" :errors="errors[`name`]" />
                    </div>

                    <button class="btn btn-primary">{{ $t('Add') }}</button>
                </form>
            </div>
        </template>
    </Modal>
</template>

<script>
import {userTypeService} from '@/services/settings/userTypeService'
import Modal from '@/components/widgets/Modal';
import FormErrors from '@/components/widgets/FormErrors';

export default {
    name: "AddUserType",
    components: {
        Modal,
        FormErrors
    },
    data() {
        return {


            userType: {
                name: null
            }
        }
    },
    methods: {
        addUserType() {
            userTypeService.addUserType(this.userType).then(response => {
                this.$store.commit('addUserGroup', response.data.data)
                this.$emit('hideComponent')
            }).catch(error => {
                this.errors = error.response.data.errors || []
            })
        }
    }
}
</script>